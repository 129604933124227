<template>
  <div class="card-activity">
    <Body1 v-if="typeof label === 'string'">{{ label }}</Body1>
    <Body1 v-else>
      <component :is="label" />
    </Body1>
    <div class="date">{{ date }}</div>
    <Icon
      name="close"
      size="medium"
      color="#CFD2D4"
      style="cursor: pointer"
    ></Icon>
  </div>
</template>

<script>
import Body1 from "@/components/Texts/Body1";
import Icon from "@/components/Icons/Icon";
export default {
  name: "Activity",
  components: { Icon, Body1 },
  props: ["label", "date"],
};
</script>

<style scoped>
.card-activity {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 220px;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
  gap: 16px;
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(33, 36, 78, 0.1);
  border-radius: 5px;
  height: 73px;
}
.card-activity .date {
  font-family: "Weissenhof Grotesk";
  font-style: normal;
  font-weight: 280;
  font-size: 17px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: 0.15px;

  /* Kermony/Gris 1 */

  color: #cfd2d4;
}
</style>
