<template>
  <div class="activities">
    <div class="activities-header">
      <Overline>Type d’activité</Overline>
      <Overline>Date</Overline>
    </div>
    <Activity
      v-for="item in items"
      :key="item.label"
      :date="item.date"
      :label="item.label"
    />
  </div>
</template>

<script>
import Activity from "@/views/App/MyAccount/Activities/Activity";
import Overline from "@/components/Texts/Overline";
export default {
  name: "Activities",
  components: { Overline, Activity },
  data() {
    return {
      items: [
        {
          label: (
            <span>
              Vous avez ajouté un <b>nouveau bien.</b>
            </span>
          ),
          date: "2 Fev 2019 à 19:28",
        },
        {
          label: (
            <span>
              Vous avez créé une <b>nouvelle donation.</b>
            </span>
          ),
          date: "30 Dec 2019 à 05:18",
        },
        {
          label: (
            <span>
              Vous avez créé une <b>nouvelle donation.</b>
            </span>
          ),
          date: "30 Dec 2019 à 04:19",
        },
        {
          label: "Vous avez changé vos informations de transmission.",
          date: "20 Dec 2019 à 23:14",
        },
        {
          label: "Vous avez ajouté un nouveau document à votre Coffre-fort.",
          date: "7 Dec 2019 à 23:26",
        },
        {
          label: (
            <span>
              Vous avez <b>modifié</b> votre <b>email.</b>
            </span>
          ),
          date: "4 Dec 2019 à 21:42",
        },
      ],
    };
  },
};
</script>

<style scoped>
.activities {
  gap: 8px;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}
.activities .activities-header {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 276px;
  padding: 8px 16px;
}
</style>
