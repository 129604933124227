<template>
  <div class="form-personal-informations">
    <div class="container-field">
      <Subtitle1 color="blue-1">Prénom</Subtitle1>
      <div class="wrapper-field">
        <input
          type="text"
          v-model="userData.firstName"
          placeholder="Prénom"
          autocomplete="off"
          disabled
        />
      </div>
    </div>
    <div class="container-field">
      <Subtitle1 color="blue-1">Nom de famille</Subtitle1>
      <div class="wrapper-field">
        <input
          type="text"
          v-model="userData.lastName"
          placeholder="Nom de famille"
          autocomplete="off"
          disabled
        />
      </div>
    </div>
    <div class="container-field">
      <Subtitle1 color="blue-1">Email</Subtitle1>
      <div class="wrapper-field">
        <input
          type="text"
          v-model="userData.email"
          placeholder="Email"
          autocomplete="off"
        />
        <!-- <Button theme="tertiary" size="medium">MODIFIER</Button> -->
      </div>
    </div>
    <div class="container-field">
      <Subtitle1 color="blue-1">Téléphone</Subtitle1>
      <div class="wrapper-field">
        <input
          type="text"
          v-model="userData.phoneNumber"
          placeholder="Téléphone"
          autocomplete="off"
        />
        <!-- <Button theme="tertiary" size="medium">MODIFIER</Button> -->
      </div>
    </div>
    <!-- <div class="container-field">
      <Subtitle1 color="blue-1">Mot de passe</Subtitle1>
      <div class="wrapper-field">
        <input type="password" placeholder="Mot de passe" autocomplete="off" />
        <Button theme="tertiary" size="medium">MODIFIER</Button>
      </div>
    </div> -->
  </div>
  <Button @click="runLogout()" style="margin-bottom: 20px"
    >SE DÉCONNECTER</Button
  >
</template>

<script>
import Subtitle1 from "@/components/Texts/Subtitle1";
import Button from "@/components/Buttons/Button";
import customerApi from "@/api/customer";
import authApi from "@/api/auth";
import { getRefreshToken } from "@/axios";

export default {
  name: "PersonalInformations",
  components: { Button, Subtitle1 },

  data() {
    return {
      userData: {},
    };
  },

  mounted() {
    this.fetchCustomerData();
  },

  methods: {
    fetchCustomerData() {
      customerApi.fetchCustomerData().then((data) => {
        this.userData = data;
      });
    },

    runLogout() {
      authApi
        .revoke({ refreshToken: getRefreshToken() })
        .then(() => {
          this.redirectToLogin();
        })
        .catch(() => {
          this.redirectToLogin();
        });
    },

    redirectToLogin() {
      this.$router.push("/login").then(() => {
        localStorage.removeItem("user");
        this.$store.commit("logout");
      });
    },
  },
};
</script>

<style scoped>
.form-personal-informations {
  gap: 16px;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 40px;
}

.container-field {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.wrapper-field {
  display: flex;
}
.container-field input {
  background: #ffffff;
  border: 1px solid #cfd2d4;
  border-radius: 5px;
  padding: 8px 0px 8px 8px;
  font-family: "Weissenhof Grotesk";
  font-style: normal;
  font-weight: 280;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #21244e;
  width: 360px;
}

.container-field input:focus {
  outline: none;
}
</style>
