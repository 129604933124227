<template>
  <WorkspaceTabs noPadding>
    <a-tabs animated>
      <a-tab-pane key="1" tab="INFORMATIONS PERSONNELLES">
        <PersonalInformations />
      </a-tab-pane>
      <!-- HIDDEN -->
      <a-tab-pane key="2" tab="ACTIVITÉ" v-if="1 == 2">
        <Activities />
      </a-tab-pane>
    </a-tabs>
  </WorkspaceTabs>
</template>

<script>
import WorkspaceTabs from "@/components/Workspaces/WorkspaceTabs";
import Activities from "@/views/App/MyAccount/Activities/Activities";
import PersonalInformations from "@/views/App/MyAccount/PersonalInformations/PersonalInformations";
export default {
  name: "MyAccount",
  components: { PersonalInformations, Activities, WorkspaceTabs },
  mounted() {
    this.$store.commit("updateHeader", {
      title: "Mon Compte",
      subtitle:
        "Retrouvez ici toutes les informations relatives à votre compte",
    });
  },
};
</script>

<style scoped></style>
